@mixin breakpoint($size) {
  @if $size == mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $size == smallmobile {
    @media (max-width: 479px) {
      @content;
    }
  } @else if $size == tablet {
    @media (max-width: 1023px) {
      @content;
    }
  } @else if $size == laptop {
    @media (max-width: 1365px) {
      @content;
    }
  } @else if $size == desktop {
    @media (max-width: 1919px) {
      @content;
    }
  }
}
