.StagesContainer {
  .MuiCard-root {
    position: relative;
    padding-bottom: 30px;
  }
  .MuiGrid-item {
    &.MuiGrid-grid-lg-2 {
      max-width: 20%;
      flex-basis: 20%;
      position: relative;

      @include breakpoint(laptop) {
        max-width: 33.33%;
        flex-basis: 33.33%;
      }
      @include breakpoint(tablet) {
        max-width: 50%;
        flex-basis: 50%;
      }

      @include breakpoint(mobile) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
  .avatar-box {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .MuiCardContent-root {
    padding: 15px 15px 5px 15px !important;
  }

  .pan {
    .MuiAvatar-colorDefault {
      background: #9bcdf8;
    }
  }
  .ekyc {
    .MuiAvatar-colorDefault {
      background: #fc4718;
    }
  }

  .bankverified {
    .MuiAvatar-colorDefault {
      background: #00589d;
    }
  }
  .signature {
    .MuiAvatar-colorDefault {
      background: #000000;
    }
  }

  .personaldetails {
    .MuiAvatar-colorDefault {
      background: orange;
    }
  }

  .ipv {
    .MuiAvatar-colorDefault {
      background: blue;
    }
  }

  .aof {
    .MuiAvatar-colorDefault {
      background: green;
    }
  }

  .esign {
    .MuiAvatar-colorDefault {
      background: black;
    }
  }
  .total {
    .MuiAvatar-colorDefault {
      background: blueviolet;
    }
  }
}

.view-all {
  display: contents;
}
