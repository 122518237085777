.footer {
  background-color: $primary-color;
  color: $white;
  padding: 10px 20px;
  font-size: 14px;
  text-align: center;
  @include breakpoint(tablet) {
    font-size: 13px;
  }
}
