.bowform {
  display: flex;
  align-items: center;
  input {
    border: none;
    border-bottom: 1px solid;
    outline: none;
    width: 120px;
    &:active,
    &:focus {
      outline: none;
    }
  }
  .MuiFormControl-fullWidth {
    width: 100px;
    margin: 0;
  }
}
