@import url("https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,400,600,700,800&display=swap");

$font-stack: Helvetica, sans-serif;
$primaryfontfamily: "Open Sans";
$primary-color: #0e224f;
$theme-color-primary: #0e224f;
$theme-color-secondary: #fff;
$theme-font-color-primary: #fff;
$theme-font-color-secondary: #0e224f;

$white: #ffffff;
$box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
$common-hover: #f5f6fa;
$radius: 4px;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.alert-msg {
  margin-top: 10px;
}
.loading {
  img {
    width: 50px;
    margin: 10px;
  }
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font: 100% $font-stack;
  color: $primary-color;
  overflow-x: hidden;
}

.common_section {
  //margin: 100px 0px 00px;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 20px;
  @include breakpoint(tablet) {
    margin-bottom: 10px;
  }
}

.listInfo {
  margin-left: 25px;
  li {
    margin-bottom: 10px;
  }
}

.MuiCheckbox-root {
  margin-left: 10px !important;
}

.csvDownloadBtn {
  display: inline-block;
  padding: 0;
  .MuiIconButton-root {
    padding: 0;
    min-width: inherit;
    &:hover {
      background: transparent;
    }
  }
  .MuiButton-root {
    padding: 0;
    min-width: inherit;
  }
  img {
    width: 25px;
  }
}

.passwordWithMask {
  position: relative;
  .eye-icon {
    position: absolute;
    right: 0;
    top: 20px;
    cursor: pointer;
  }
  .brokerlogin {
    position: absolute;
    right: 0;
    bottom: -25px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    color: #10275a;
    &:hover {
      text-decoration: underline;
    }
  }
}
.not-found {
  h3 {
    margin-bottom: 10px;
  }
}

.table_Section {
  .MuiToolbar-gutters {
    .material-icons {
      font-size: 25px !important;
    }
    h6 {
      font-size: 20px !important;
    }
  }
  table th:first-child {
    padding-left: 20px !important;
  }
}
main {
  min-height: calc(100vh - 36px);
  box-sizing: border-box;
}
.clapse {
  .MuiIconButton-root {
    color: #000 !important;
  }
  margin-bottom: 30px;
}
// ::-webkit-scrollbar {
//   width: 10px;
//   border-radius: 50%;
// }
// ::-webkit-scrollbar-track {
//   background: #fff;
//   border-radius: 5px;
// }
// ::-webkit-scrollbar-thumb {
//   background: #dee1ed;
//   border-radius: 5px;
// }
// ::-webkit-scrollbar-thumb:hover {
//   background: #7d87a0;
//   border-radius: 5px;
// }
.mainSection {
  display: flex;
}
.ToolbarP {
  width: 100%;
  padding: 20px;
  overflow: auto;
  padding-top:65px !important;
  @include breakpoint(tablet) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @include breakpoint(mobile) {
    padding-left: 15px;
    padding-right: 15px;
  }
  &.makeStyles-paddingT-16,
  &.jss16 {
    @include breakpoint(mobile) {
      padding-top: 63px;
    }
    .StagesContainer.MuiGrid-spacing-xs-4 {
      margin-bottom: 30px;
      @include breakpoint(tablet) {
        width: calc(100% + 16px);
        margin: -8px;
      }
      @include breakpoint(mobile) {
        width: 100%;
        margin: 10px 0 20px;
      }

      .MuiGrid-item {
        padding: 10px;
        @include breakpoint(mobile) {
          padding: 0;
          margin-bottom: 15px;
        }
        .MuiGrid-item {
          padding: 0;
        }
      }
    }
  }

  .listContainer {
    width: 100%;
    overflow-x: auto;
    max-height: 1080px;
    .MuiSvgIcon-root {
      width: 18px;
      vertical-align: middle;
    }

    .MuiIcon-colorAction {
      color: red;
    }
    .actionBTN {
      margin-left: 0;
    }
    thead {
      background: #e8eaf5;
      padding: 5px 13px;
      th {
        background: #e8eaf5;
      }
      .MuiTableCell-root {
        padding:  10px;
        font-size: 13px;
        white-space: pre;
        font-weight: 600;
        border-right: 0px solid rgb(243, 243, 243);
        border-bottom: 1px solid rgb(243, 243, 243);
        @include breakpoint(mobile) {
          padding: 5px 8px;
        }

        .MuiButton-text {
          padding: 0 5px 0 0;
          min-width: inherit;
        }
      }
    }
    tbody {
      .MuiTableCell-root {
        padding: 7px 10px;
        font-size: 13px;
        white-space: pre;
        border-right: 1px solid rgb(243, 243, 243);
        border-bottom: 1px solid rgb(243, 243, 243);
        @include breakpoint(mobile) {
          padding: 5px 8px;
        }
        .MuiButton-text {
          padding: 0 5px 0 0;
          min-width: inherit;
        }
      }

      .MuiIconButton-root {
        padding: 4px 6px 4px 6px;
        &.green {
          .MuiSvgIcon-root {
            color: green;
          }
        }

        &.red {
          .MuiSvgIcon-root {
            color: red;
          }
        }
        // .MuiIconButton-label {
        //   align-items: center;
        //   justify-content: center;
        // }
      }
    }
  }
}
.tableP {
  width: 100%;
}

.primary-btn {
  color: #fff;
  background-color: #3f51b5;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 6px 16px;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  .MuiSvgIcon-root {
    color: lightgoldenrodyellow !important;
    margin-left: 5px;
  }
}

// Stage
.redBar {
  width: 74px;
  height: 20px;
  background: red;
  margin: auto;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}
.greenBar {
  background: green;
  left: 0;
  top: 0;
  width: 30%;
  height: 100%;
  position: absolute;
  color: #fff;
  padding: 0 2px;
  box-sizing: border-box;
  &.zero {
    background: none;
  }
}
.MuiDialog-paperWidthSm {
  max-width: 350px;
  width: 350px;
}
.stage-popup {
  position: relative;
  .PrivateSwitchBase-root-18 {
    padding: 5px;
  }

  .MuiCheckbox-root {
    margin: 0px !important;
  }
}
.closeBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.nametitle {
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 10px;
}
.MuiDialogTitle-root {
  padding: 16px 24px 5px 24px !important;
  cite {
    font-size: 16px;
    line-height: 22px;
  }
}
.MuiDialogActions-root {
  padding: 15px;
}

.download {
  &.green {
    svg {
      color: green;
    }
  }
  &.red {
    svg {
      color: red;
    }
  }
}
.MuiFormControlLabel-root {
  margin-left: -8px;
}
.fullwidthTextarea {
  width: 100%;
  height: 100px !important;
  padding: 10px;
  resize: none;
  &.red {
    border-color: red;
  }
}

.red {
  color: red;
}

.searchform {
  .MuiButton-containedPrimary {
    line-height: 1.5;
    margin-left: 5px;
  }
}
.newUiupadte
{
  background: #fff;
    padding: 18px 18px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 3px 15px #00000029;
    box-shadow: 0px 3px 15px #00000029;
}
.searchUpdatesectin
{
  background: #fff;
  margin-bottom: 20px;
  border-radius: 10px;
  // height: 40px;
padding:0px 10px;
  -webkit-box-shadow: 0px 3px 15px #00000029;
  box-shadow: 0px 3px 15px #00000029;
.csvDownloadBtn
{
  float: right;
}
}
.search
{
  text-align: left;
  .searchform
  {
    float: left;
    width:80%;
  }
  .form-control {
    width:80%;
    float: left;
  }
button {
  float: left;
}
}
.loading
{
  text-align:center;
  width:100% !important;
  position: absolute;
  height: 80%;
  img 
  {
    display: inline-block;
    position: relative;
    top:30%; 
  }
}
.csvDownloadBtn img {
  width: 33px;
}
.newUiupadte
{
  min-height: 300px;
  position: relative;
  .MuiPaper-elevation1
  {
    box-shadow: none;
    border-left:1px solid #f3f3f3;
    border-right:1px solid #f3f3f3;
  }
  table 
  {
    tr 
    {
      td 
      {
        &:first-child 
        {
          border-left:0px solid #f3f3f3;
          text-align: center;
        }
        &:last-child 
        {
          border-right:0px solid #f3f3f3;
        }
      }
    }
  }
}

.mainCt
{
  background: #F2F3F7;
  border-radius: 20px;
  height: 90vh;
  overflow: hidden;
  padding-top: 20px;
}
.innerprofilesection
{
  padding: 20px;
  overflow: scroll;
  height: 90vh;
  padding-top: 0px;
}
header.MuiAppBar-colorPrimary
{
  box-shadow: none !important;
}
#sidebar 
{
  .MuiDrawer-paperAnchorDockedLeft {
    border-right: 0px solid rgba(0, 0, 0, 0.12) !important;
}
}
.loadingMain
{
  position:fixed !important;
  z-index: 9999;
  top: 0 !important;
  bottom: 0 !important;
  width: 100%;
  background:#00000091;
  left: 0;
    right: 0;
    .loaderE
    {
      position: absolute;
    top: 50%;
    left: 50%;
    }
}
.uploadPt
{
  height:auto !important;
  margin-bottom: 25px;
  .newUiupadte
  {
    height:auto !important;

    min-height: auto;
    padding: 0;

  }
  .dpinformation
  {}
}
.mainCt .uploadPt
{
 padding-left:0px;
 padding-right:0px;
}
.avatarT
{
  display: inline-flex !important;
  margin-right: 10px;
  vertical-align: sub;
  width: 30px !important;
  height: 30px !important;
  font-size: 20px !important;
}
.stUSts 
{
  text-align:center !important;
  span 
  {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    pointer-events: none;
    color:#fff;
    min-width: 57px;
    display: inline-block;
    text-align: center;
    padding:3px 0px;
  }
  .active
  {
    background-color:green;
  }
  .inactive
  {
    background-color:red;
  }
}
.failedR
{
  background-color: rgb(253, 236, 234);
}
.cutomeFac .MuiDialog-paperWidthSm
{
  max-width: inherit !important;
  width: 50% !important;
  position: relative;
  .cls_btn
  {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
  }
}

.rejectBTn_popup  .MuiDialog-paperWidthSm
{
  z-index:9999;
}
.cutomeFac .MuiDialogContent-root {
  padding: 8px 10px !important;
  h4 {
    margin-bottom: 10px;
    font-size: 15px;
}
  ul 
  {
    list-style: none;
    li 
    {
      margin-bottom: 5px;
    }
  }
}
form 
{
  padding:20px;
  width: 100%;
.commonInput
{
    
    .MuiFormControl-root
    {
      width: 100%;
    }
  }
  .form-btn
  {
    margin-top:20px;
  }
}