.sidebarSection {
  .makeStyles-drawerOpen-6,
  .jss6 {
    @include breakpoint(tablet) {
      width: 220px;
    }
    @include breakpoint(mobile) {
      width: 100%;
    }
  }

  .MuiList-root {
    .MuiListItem-gutters {
      @include breakpoint(tablet) {
        padding: 8px;
      }
    }
    .MuiListItemIcon-root {
      @include breakpoint(tablet) {
        min-width: 48px;
      }
    }
  }

  @include breakpoint(mobile) {
    width: 100%;
    display: none;
  }

  &.show {
    display: block;
    width: 100%;
  }

  .MuiCollapse-container {
    .MuiListItem-gutters {
      @include breakpoint(tablet) {
        padding: 5px;
      }
    }
    .MuiListItemText-inset {
      @include breakpoint(tablet) {
        padding-left: 10px;
        margin: 0;
      }

      @include breakpoint(mobile) {
        padding-left: 50px;
      }
      span.MuiTypography-body1 {
        @include breakpoint(tablet) {
          font-size: 0.8rem;
        }
        @include breakpoint(tablet) {
          font-size: 1rem;
        }
      }
    }
  }
  .jss8,
  .makeStyles-toolbar-8 {
    @include breakpoint(mobile) {
      min-height: 48px;
    }
  }

  .jss7,
  .makeStyles-drawerClose-7 {
    @media (min-width: 600px) {
      width: 57px;
    }
  }
}
.makeStyles-drawerClose-7 {
  @media (max-width: 767px) {
    width: 270px !important;
  }
}
@media (max-width: 767px) {
  .searchform
  {
    position: relative;
  }
  .search 
  {
  button {
    float: left;
    position: absolute;
    right: 4%;
    padding: 6px 14px;
    width: auto;
    min-width: auto;
}
.form-control {
  width: 100%;
  float: left;
}
}
}