.commonS {
  padding: 0px !important;
}
.signin-container {
  padding: 0px !important;
  background-image: url(../../images/loginback.jpg);
  background-size: cover;
  max-width: 100%;
  margin: 0px !important;
  min-height: 100vh;
  display: block;
  justify-content: center;
  align-items: center;
  position: static;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;

  @include breakpoint(mobile) {
    display: block !important;
    width: 100% !important;
    padding: 20px 0 !important;
  }

  .MuiGrid-grid-xs-4 {
    @include breakpoint(tablet) {
      padding: 10px;
      max-width: 40%;
      flex-basis: 40%;
    }

    @include breakpoint(mobile) {
      padding: 10px !important;
      max-width: 85%;
      flex-basis: 85%;
      margin: auto;
      text-align: center;
    }

    .headingTXT {
      font-size: 39px;
      font-weight: 500;
      @include breakpoint(mobile) {
        font-size: 30px;
        line-height: 35px;
      }
    }
  }

  form {
    width: 100%;
  }

  .FormikField {
    margin: 0px 0px 25px 0;
  }
  .signin-box {
    padding: 20px;
    @include breakpoint(mobile) {
      padding: 0;
    }
    .makeStyles-root-14 {
      position: relative;
      /* width: 90%; */
      margin-top: 13px;
      .MuiAlert-action {
        position: absolute;
        top: -17px;
        right: 0;
        color: red;

        .MuiAlert-icon {
          padding-right: 15px;
        }
      }
      .MuiSvgIcon-fontSizeInherit {
        color: #303030;
        position: absolute;
      }
    }
  }
  button {
    display: block;
    font-size: 14px;
  }
  .content_side_userLS {
    h1 {
      color: $white;
      font-size: 22px;
      font-weight: 400;
      @include breakpoint(mobile) {
        font-size: 14px;
        line-height: 19px;
      }
    }
    img {
      width: 250px;
      @include breakpoint(mobile) {
        width: 150px;
        margin-bottom: 10px;
      }
    }
  }
}

.Mui-required.MuiFormHelperText-root {
  color: red;
  position: absolute;
  bottom: -20px;
}
.wdsection {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
}
