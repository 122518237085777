.MuiListItemIcon-root {
  color: $theme-font-color-secondary !important;
  display: inline-flex;
  min-width: 56px;
  flex-shrink: 0;
}
.MuiTypography-displayBlock,
.MuiIconButton-root {
  color: $theme-font-color-secondary !important;
}
.makeStyles-drawerClose-8 {
  width: 60px !important;
}

.menuLink {
  .MuiListItem-button {
    padding: 15px 19px;
    text-transform: uppercase;
    .homd {
      margin-right: 18px;
    }
    .MuiSvgIcon-root {
      float: right;
    }
  }
  .submenu {
    background-color: #f5f5f5;
  }
  a {
    display: block;
    color: $theme-font-color-secondary;
    padding: 15px 7px;
    border-radius: inherit;
    padding-left: 10px;
    text-transform: capitalize;
    text-decoration: none;

    .MuiSvgIcon-root {
      vertical-align: sub;
      margin-left: 12px;
      margin-right: 15px;
    }
  }
}
.userInfo {
  padding: 20px;
  background: rgb(16, 39, 90);
  text-align: left;
  color: $theme-font-color-primary !important;
  padding-left: 70px;
  @include breakpoint(tablet) {
    padding: 15px 15px 15px 55px;
  }

  p {
    margin: 0;
    margin-top: 5px;
    font-size: 11px;
    color: #f5f6fa;
    @include breakpoint(tablet) {
      margin-top: 2px;
      font-size: 10px;
    }
  }
  .MuiSvgIcon-root {
    font-size: 41px;
    position: absolute;
    page-break-after: always;
    left: 8px;
    @include breakpoint(tablet) {
      font-size: 30px;
      left: 8px;
    }
  }
}
.makeStyles-usernmae-10 {
  background: transparent !important;
  box-shadow: none !important;
  padding: 0px;
  color: #fff !important;
  font-size: 18px;
  border-radius: 0px !important;
}
header {
  box-shadow: $box-shadow !important;
  &.MuiAppBar-colorPrimary {
    background-color: $theme-color-secondary !important;
    border: 0px !important;
    box-shadow: $box-shadow;
  }

  &.makeStyles-appBarShift-4,
  &.jss4 {
    @include breakpoint(tablet) {
      width: calc(100% - 220px);
    }
    @include breakpoint(mobile) {
      width: 100%;
    }
  }
  .mobileHamburger {
    display: none;
    @include breakpoint(mobile) {
      display: inline-block;
    }
  }
  .mobileHideDesktopHamburger {
    .MuiIconButton-edgeStart {
      margin-left: -22px;
    }
    @include breakpoint(mobile) {
      display: none;
    }
  }
  button {
    &.makeStyles-menuButton-1 {
      margin-right: 10px;
    }
  }

  .logoB {
    display: block;
    float: right;
    width: 100%;
    img {
      max-height: 28px;
      max-width: 250px;
      background: #fff;
      padding: 0;
      top: 3px;
      position: relative;
      border-radius: 4px;
      @include breakpoint(tablet) {
        height: 40px;
      }
      @include breakpoint(mobile) {
        height: 25px;
      }
    }
  }
  .logo {
    p {
      margin: 0;
      font-size: 10px;
      color: $theme-font-color-secondary !important;
    }
    img {
      width: 130px;
      @include breakpoint(tablet) {
        width: 80px;
      }
    }
  }
  .MuiToolbar-regular {
    @include breakpoint(mobile) {
      min-height: 48px;
    }
  }
}
