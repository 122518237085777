.addDatasection {
  .formW {
    width: 30%;
    margin: 0px 1%;
    margin-bottom: 25px;
    position: relative;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0px;
    margin-bottom: 8px;
    @include breakpoint(tablet) {
      margin-bottom: 0;
    }
  }
  .MuiPaper-root {
    position: absolute;
    top: 48px;
    z-index: 9;
    width: 100%;
    box-shadow: none;
    ul {
      list-style: none;
      padding: 10px 0px;
      margin: 0;
      border-radius: $radius;
      height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      li {
        padding: 10px;
        &:hover {
          background-color: $common-hover;
          cursor: pointer;
        }
      }
    }
  }
  .MuiFormHelperText-root.Mui-error {
    color: #f44336;
    position: absolute;
    bottom: -22px;
  }
}
.clapse {
  .MuiExpansionPanelSummary-content {
    .changeBTN {
      display: block;
      transition: 1m;
    }
  }
  .Mui-expanded {
    .changeBTN {
      display: none !important;
      transition: 1m;
    }
  }
}
.MuiButton-containedPrimary {
  color: #fff;
  background-color: #596edc;
}
#query-helper-text {
  position: absolute;
  top: 48px;
}

.dpinformation {
  width: 100%;
  padding: 20px;
  .divider {
    margin-bottom: 0;
  }
  @include breakpoint(tablet) {
    padding: 10px;
  }
}
.addDatasectionbroker {
  .divider {
    width: 100%;
    padding: 20px 10px;
    border: 1px dashed #e8eaf5;
    background: #fbfbfb;
    margin-bottom: 18px;
    box-sizing: border-box;

    .MuiFormControl-marginNormal {
      margin-top: 5px;
      @include breakpoint(tablet) {
        margin-top: 0;
      }
    }
    h3 {
      display: block;
      clear: both;
      margin: 0px;
      padding-left: 1%;
      @include breakpoint(tablet) {
        margin-bottom: 10px;
      }
    }
    h2 {
      padding-left: 1%;
      margin-top: 0px;
    }
    .formW {
      width: 23%;
      margin: 0px 1%;
      display: inline-block;
      @include breakpoint(laptop) {
        width: 48%;
      }

      @include breakpoint(mobile) {
        width: 100%;
        margin: 0;
      }
    }
  }
  .commmonSe {
    margin-bottom: 20px;
    clear: both;
    @include breakpoint(tablet) {
      margin-bottom: 10px;
    }
  }
  .MuiRadio-colorPrimary {
    color: #3f51b5 !important;
  }
  .bottonSec {
    margin: 10px 0 30px;

    &.alignRight {
      text-align: right;
    }
    @include breakpoint(tablet) {
      margin: 0;
    }
    &.mt-10 {
      margin-top: 10px;
    }
  }
}
.formW1 {
  width: 100% !important;
  div {
    margin-bottom: 20px;
  }
}
.formW-md-3 {
  width: 48%;
  display: inline-block;
  margin: 0px 1%;
  @include breakpoint(mobile) {
    width: 100%;
    margin: 0;
  }
}
span {
  &.label {
    min-width: 160px;
    display: inline-block;
  }
}
.addEditUserForm,
.transactionForm {
  width: 100%;
  .formW {
    .MuiFormControl-root {
      display: flex;
      option {
        padding: 0 5px;
      }
    }
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}
.dblock {
  display: block !important;
  width: 100% !important;
  .MuiFormGroup-root {
    display: inline;
  }
}
legend.MuiFormLabel-root {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.87);
}
.radiogroup {
  color: rgba(0, 0, 0, 0.54);
  .MuiSvgIcon-root {
    width: 0.9rem;
    height: 0.9rem;
  }
  .PrivateSwitchBase-root-18 {
    padding: 3px 3px 3px 10px;
  }
}
.csvupload
{
  .divider 
  {
    padding: 20px 20px !important;
  h2 {
    padding-left: 0% !important;
    margin-top: 0px;
  }
  }
  h3 
  {
    color: #f44336;padding-left: 0% !important;margin-bottom: 28px !important;
    font-weight: 500;
  }
.hide
{
  margin-bottom: 20px;
}
.btn {
  border: 1px solid gray;
  color: gray;
  background-color: #11275a;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
}
 .card-user
 {
  cursor: pointer;
  width: auto;
  display: inline-block;
  max-width: 300px;
 } 
}

.content {
  position: relative;
  overflow: hidden;
  display: inline-block;
}



.content input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}